<template>
  <view-item title="配件生产">
    <template #operation>
      <en-button type="primary" @click="operation.add.click">创建单据</en-button>
      <button-ajax v-if="form.data.id && form.data.status?.code === 'PR'" :method="operation.produce.click"> 确认生产 </button-ajax>
      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="detail">单据信息</en-dropdown-item>
          <en-dropdown-item command="delete">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        :ajax="{ action: 'GET /enocloud/manufacturing/production', params: (params) => (params.payload = { rsexp: 'id,serialNo,status' }) }"
        :props="{ start: 'startDate', end: 'endDate' }"
        @row-click="manifest.row.click"
        @expand-click="dialog.visible = true"
      >
        <en-table-column label="生产单号" prop="serialNo"></en-table-column>
        <en-table-column label="状态" prop="status.message"></en-table-column>

        <template #form="{ data }">
          <en-form-item label="单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="编码">
            <en-input v-model="data.goodsSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="配件名称">
            <en-input v-model="data.goodsName"></en-input>
          </en-form-item>
          <en-form-item label="制单人">
            <select-maintain
              v-model="data.preparedBy"
              :ajax="{ action: 'GET /enocloud/common/user', params: (params, name) => (params.payload = { name }) }"
              :props="{ label: 'name', value: 'name' }"
              remote
              allow-create
              class="w-full"
            ></select-maintain>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex gap-6">
        <span>单号：{{ form.data.serialNo }}</span>
        <en-tag>{{ form.data.status?.message }}</en-tag>
        <span>{{ form.data.preparedBy?.name }}</span>
        <span>{{ form.data.materialWarehouse?.name }}</span>
        <span>{{ form.data.productWarehouse?.name }}</span>
        <span>{{ formatDate(form.data.preparedDatetime, true) }}</span>
        <span>{{ form.data.comment }}</span>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-table :data="form.data.goods" :height="height" :cell-style="form.goods.cellStyle">
              <en-table-column label="编码" prop="goodsSpecification.goods.serialNo"></en-table-column>
              <en-table-column label="名称" prop="goodsSpecification.goods.name"></en-table-column>
              <en-table-column label="库存">
                <template #default="{ row }: { row: EnocloudManufacturingDefinitions['ManufacturingProductionGoodsDto'] }">
                  {{ formatNumber(100, 1) }}
                </template>
              </en-table-column>
              <en-table-column label="数量" prop="count"></en-table-column>
              <en-table-column label="规格" prop="goodsSpecification.name"> </en-table-column>
              <en-table-column label="基本单位换算">
                <template #default="{ row }: { row: EnocloudManufacturingDefinitions['ManufacturingProductionGoodsDto'] }">
                  {{ `${row.goodsSpecification?.weight}${row.goodsSpecification?.goods?.warehouseUnit}` }}
                </template>
              </en-table-column>
              <en-table-column label="产地" prop="goodsSpecification.goods.placeOfProduction"></en-table-column>
            </en-table>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <inventory-manufacturing-detail v-model="detail.visible" :data="form.data" @confirm="detail.confirm"></inventory-manufacturing-detail>

  <inventory-manufacturing-dialog v-model="dialog.visible"></inventory-manufacturing-dialog>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'
import InventoryManufacturingDetail from '@spray/components/inventory-manufacturing-detail.vue'
import InventoryManufacturingDialog from '@spray/components/inventory-manufacturing-dialog.vue'

export default factory({
  components: { InventoryManufacturingDetail, InventoryManufacturingDialog },

  config: {
    children: {
      operation: {
        add: {
          async click() {
            this.form.init()
            this.detail.visible = true
          }
        },
        produce: {
          async click() {
            await this.form.produce()
            return this.form.get()
          }
        },
        option: {
          async command(option: string) {
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
              case 'delete':
                await this.form.delete()
                this.form.get()
                break
            }
          }
        }
      },
      manifest: {
        row: {
          async click(row: EnocloudManufacturingDefinitions['ManufacturingProductionDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/manufacturing/production/:productionId',
            data: 'object',
            loading: true,
            init: true,
            dirty: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          delete: {
            action: 'DELETE /enocloud/manufacturing/production/:productionId',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          produce: {
            action: 'POST /enocloud/manufacturing/production/:productionId/produce',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        children: {
          goods: {
            cellStyle({ row }: { row: EnocloudManufacturingDefinitions['ManufacturingProductionGoodsDto'] }) {
              const counts = row.goods?.inventoryConfigs?.reduce((count, item) => calculator.add(item.totalCount, count), 0)
              return { color: row.count && row.count > counts ? 'red' : '' }
            }
          }
        }
      },
      detail: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      dialog: {
        visible: false
      }
    }
  }
})
</script>
